import "process";

const piceTargetOrigin = process.env.TARGET_ORIGIN;

window.addEventListener("load", () => {
  if (!piceTargetOrigin || piceTargetOrigin.length === 0) return;

  const query = location.href.split("?")[1];
  if (!query) return;

  if (window.parent) {
    window.parent.postMessage(query, piceTargetOrigin);
  }
  if (window.opener) {
    window.opener.postMessage(query, piceTargetOrigin);
  }
});
